import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Flex from "../../components/flex"
import CaseStudyCard from "../../components/caseStudyCard"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allCaseStudiesListYaml {
        edges {
          node {
            project
            type
            link
            image {
              childImageSharp {
                fluid(maxWidth: 275) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Case Studies" />
      <Flex
        mt={5}
        flexDirection="column"
        alignItems="center"
      >
        <h1>Case Studies</h1>
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="center"
        my={4}
      >
        <Flex
          flexWrap="wrap"
          maxWidth="900px"
          alignItems="center"
          justifyContent="center"
        >
          {
            data.allCaseStudiesListYaml.edges.map(({ node }) => (
              <CaseStudyCard
                key={node.project}
                {...node}
              />
            ))
          }
        </Flex>
      </Flex>
    </Layout>
  );
}

export default IndexPage
